import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import get from "lodash/get";
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import Newsletter from "../components/signUp"
import CustomReactShare from "../components/CustomReactShare"
import SubstackSolo1 from "../components/subStackSolo";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next, readingTime } = this.props.pageContext
    const excerpt = get(this.props, "data.excerpt");
const url = `${get(this.props, "data.site.siteMetadata.siteUrl")}${get(
  this.props,
  "pathContext.slug"
)}`;

    return (
     <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <header>
        <h1>{post.frontmatter.title}</h1>
        <p style={{fontFamily:'Inter;'}}>
         {post.frontmatter.date}
        </p>
        </header>
        <MDXRenderer>{post.body}</MDXRenderer>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <p><strong>Subscribe for reading recommendations, exclusive insights, and updates on future posts like this:</strong></p>
        <p><SubstackSolo1/></p>

        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={`/blog${previous.fields.slug}`} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/blog${next.fields.slug}`} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
        <CustomReactShare title={post.frontmatter.title} excerpt={excerpt} url={url} />
      </Layout> 
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    mdx(fields: {slug: {eq: $slug}}) {
      body
      id
      excerpt(pruneLength: 160)
      fields {
        slug
        readingTime {
          text
        }
      }
      frontmatter {
        title
        tag
        date(formatString: "MMMM DD, YYYY")
        description
      }
    } 
  }
`
