import PropTypes from "prop-types";
import React from "react";
import {FaTwitter} from "react-icons/fa";
import {FaFacebook} from "react-icons/fa";
import {FaEnvelope} from "react-icons/fa";
import {FaPinterest} from "react-icons/fa";
import {FaLinkedin} from "react-icons/fa";
import { ShareButtonRectangle, ShareBlockStandard } from "react-custom-share";
import { css } from "emotion";

/*This is sharing buttons within each blog post*/
const CustomReactShare = props => {
    const { url, title, excerpt } = props;
    const customStyles = css`
      border-radius: 50% 0 50% 0;
      margin: 0 10px;
      flex-basis: 60px;
      height: 60px;
      flex-grow: 0;
    `;

    const shareBlockProps = {
        url: url,
        button: ShareButtonRectangle,
        buttons: [
          { network: "Twitter", icon: FaTwitter },
          { network: "Facebook", icon: FaFacebook },
          { network: "Linkedin", icon: FaLinkedin },
        ],
        text: title,
        longtext: excerpt,
        buttonClassName: customStyles
      };
    
      return <ShareBlockStandard {...shareBlockProps} />;
    };
    
    CustomReactShare.propTypes = { 
        url: PropTypes.string,
        title: PropTypes.string,
        excerpt: PropTypes.string
      };
    CustomReactShare.defaultProps = {
         url: 'url',
         title: 'title',
         excerpt: ''
          };
    
    export default CustomReactShare;