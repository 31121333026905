import React from "react"
import addToMailchimp from  'gatsby-plugin-mailchimp'

// This signup form is for the home page
class Newsletter extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            email: '',
            message: 'Subscribe to get early access to new posts and guides'
        }

    }

    changeEmailHandler = (event) =>{
        this.setState({email: event.target.value})

    }

     // 1. via `.then`
  handleSubmit = e => {
    e.preventDefault();
    this.setState({message: 'Thank you for subscribing'})
    addToMailchimp(this.state.email) // listFields are optional if you are only capturing the email address.
    .then(data => {
      // I recommend setting data to React state
      // but you can do whatever you want (including ignoring this `then()` altogether)
      console.log(data)
    })
    .catch(() => {
      // unnecessary because Mailchimp only ever
      // returns a 200 status code
      // see below for how to handle errors
    })
    this.setState({email: ''})
  }

    render(){
        return(
            <>

              <div className="News" style={{fontFamily: 'Inter' }}>
                    <div className="letter">
                <strong>{this.state.message}</strong>
                <div className="form">
                    <form className="subscribe" onSubmit={this.handleSubmit}>
                        <input type="email" value={this.state.email} onChange={this.changeEmailHandler} name="EMAIL" id="mce-email"
                        className="subscribe-email" placeholder="Your Email Here" required />
                        <button className="btn-send" type="submit" style={{backgroundColor:"maroon", borderColor:'maroon', borderRadius: "6px", color:"white"}}><strong>Subscribe</strong></button>
                    </form>
                </div>
                    </div>
        </div> 
            </>
        )
    }
}

export default Newsletter